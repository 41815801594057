// Variables
@import 'variables';
@import 'fonts';
@import '~bootstrap';
@import "mixins";
//@import "~@fortawesome/fontawesome-free";
// Plugins
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";
@import "~lightslider/dist/css/lightslider.css";
@import "lightslider";
@import "slicknav.css";
@import "_slicknav.scss";

* {
  font-family: "Roboto Condensed";
  font-size: 16px;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover, &.active {
    color: $orange;
    text-decoration: none;
  }
}

ul {
  li {
    &.active {
      color: $orange;
    }
  }
}

.orange {
  color: $orange;
}

nav {
  background: $white;
  font-size: 1em;
  #top_nav {
    top: 0px;
    left: 0px;
    height: 146px;
  }
}

.site_logo_container {
  width: auto;
  img {
    display: block;
    width: 100px;
    height: 100px;
  }
}

#banner-slider {
  width: 100%;
  height: 559px;
  opacity: 1;
  .slide {
    position: relative;
    img {
      max-height: 559px;
      width: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
    }

    .content {
      z-index: 900;

      h1 {
        font-family: "Helvetica Neue";
        font-size: 60px;
      }
    }
  }
}

.image-overlay {
  transition: .5s ease;
  opacity: 0.85;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 800;
}

#innerpage_banner {
  height: 250px;
  .innerpage_banner_image {
    object-fit: cover;
    object-position: center;
  }
}

.test {
  display: flex; // make us of Flexbox
  align-items: center; // does vertically center the desired content
  justify-content: center; // horizontally centers single line items
}

#offers, #rentals {
  .offer-box {
    height: 435px;
    @include box-shadow(3px, 3px, 5px, 0px, rgba(0, 0, 0, 0.10));
    img {
      height: 234px;
      object-fit: cover;
    }
  }
}

.galery {
  .galery-item {
    height: 250px;
    img {
      object-fit: cover;
    }
  }
}

input {
  height: 35px;
}

*:focus {
  outline: none;
}

.button {
  display: inline-block;
  width: 200px;
  height: 35px;
  text-align: center;
  color: $white;
  text-transform: uppercase;
  padding: 5px 10px;

  &.light {
    background: $orange;
    &:hover {
      background: $black;
      color: $white;
    }
  }

  &.dark {
    background: $black;
    &:hover {
      background: $white;
      color: $black;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.newsletter_subscription {
  background: $orange;
  height: 115px;
}

/** Hide Froala license notice */
p[data-f-id="pbf"] {
    display: none;
}

@media screen and (max-width: 768px) {
  #banner-slider, .lSSlideOuter {
    display: none;
  }

  .newsletter_subscription {
    height: 175px;
  }

  footer {
    .site_logo_container img {
      margin: 0 auto;
    }
  }
}

.map {
  .info {
    background: $black;
    opacity: 0.8;
  }
}

