.slicknav_menu {
  display: none;
  background: $black;
}

.slicknav_btn {
  background: $orange;
  text-shadow: none;
  border-radius: 0px;
}

.slicknav_nav {
  ul {
    margin: 0;
  }
}

@media screen and (max-width: 40em) {
  /* #menu is the original menu */
  #mobile_nav {
    display: none;
  }

  .slicknav_menu {
    display: block;
  }
  #top_nav {
    height: auto !important;
  }
}