/*@font-face {
  font-family: "Roboto Condensed";
  src: url('/public/fonts/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-family: "Roboto Condensed Bold";
  src: url('/public/fonts/RobotoCondensed-Bold.ttf');
}

@font-face {
  font-family: "Roboto Condensed Light";
  src: url('/public/fonts/RobotoCondensed-Light.ttf');
}*/

h3 {
  color: $orange;
  font-family: "Roboto Condensed";
}

p {
  font-family: "Roboto Condensed";
}